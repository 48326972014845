import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import { DocumentNode, OperationDefinitionNode, print, visit } from "graphql";
import { useCallback, useMemo } from "react";
import {
  FetchQueryOptions,
  UseInfiniteQueryOptions,
  UseMutationOptions,
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";

const GRAPHQL_ENDPOINT = process.env.NEXT_PUBLIC_API_ENDPOINT;

type fetchPolicy = "cache-first";

export class GraphqlError extends Error {
  public status;
  public operationName;
  public query;

  constructor(message?: string, status?: number, operationName?: string, query?: string) {
    super(message || "Unknown Error");
    this.status = status;
    this.operationName = operationName;
    this.query = query;
  }
}

const getOperationName = (query: DocumentNode) => {
  let operationName;
  visit(query, {
    OperationDefinition(node: OperationDefinitionNode) {
      operationName = node.name?.value;
    },
  });
  return operationName;
};

const graphqlCache = new Map<string, any>();

export const graphqlFetch = async <TData = any, TVariables = Record<string, any>>(
  operation: TypedDocumentNode<TData, TVariables>,
  variables?: TVariables,
  options?: { fetchPolicy: fetchPolicy },
): Promise<TData> => {
  const operationName = getOperationName(operation) || "";
  const query = print(operation);

  const body = JSON.stringify({ operationName, query, variables });

  if (options?.fetchPolicy === "cache-first" && graphqlCache.has(body)) {
    console.log("Cache hit!");
    return graphqlCache.get(body);
  } else if (options?.fetchPolicy === "cache-first") {
    console.log("Cache miss!");
  }

  let res: Response;
  try {
    res = await fetch(GRAPHQL_ENDPOINT as string, {
      headers: { "content-type": "application/json", "apollographql-client-name": "browse", "x-tenant-id": "lifex" },
      method: "POST",
      body,
    });
  } catch (error: unknown) {
    // Network-level error
    console.error("Network error or could not reach server.");
    console.error(error);
    throw new GraphqlError("Network error or could not reach server.", 0, operationName, query);
  }

  // 2. Check for non-2xx HTTP status
  if (!res.ok) {
    // 2a. Read the body as text so we don't blow up on non-JSON
    const bodyText = await res.text();
    console.error(`HTTP error ${res.status}: ${res.statusText}\nBody: ${bodyText}`);
    throw new GraphqlError(
      `HTTP error ${res.status}: ${res.statusText}\nBody: ${bodyText}`,
      res.status,
      operationName,
      query,
    );
  }

  let json;
  try {
    json = await res.json();
  } catch {
    console.error("Failed to parse JSON from server response.");
    throw new GraphqlError(res.statusText, res.status, operationName, query);
  }

  if (Array.isArray(json.errors)) {
    const [error] = json.errors;
    throw new GraphqlError(error.message, res.status, operationName, query);
  }

  if (options?.fetchPolicy === "cache-first") {
    graphqlCache.set(body, json.data);
  }

  return json.data;
};

export const useGraphqlQuery = <TData = any, TVariables = Record<string, any>>(
  operation: TypedDocumentNode<TData, TVariables>,
  variables?: TVariables,
  options?: UseQueryOptions<TData, GraphqlError>,
) => {
  const operationName = useMemo(() => getOperationName(operation), [operation]);
  const queryKey = useMemo(() => [operationName, variables ?? {}], [operationName, variables]);
  return useQuery(
    queryKey,
    () => graphqlFetch(operation, variables),
    // TODO: fix typing @jpagand any idea?
    options as any,
  );
};

export const useGraphqlFetch = <TData = any, TVariables = Record<string, any>>(
  operation: TypedDocumentNode<TData, TVariables>,
) => {
  const queryClient = useQueryClient();
  return useCallback(
    (variables?: TVariables, options?: FetchQueryOptions<TData, GraphqlError>) => {
      const operationName = getOperationName(operation);
      return queryClient.fetchQuery<TData, GraphqlError>(
        [operationName, variables ?? {}],
        () => graphqlFetch(operation, variables),
        options,
      );
    },
    [operation, queryClient],
  );
};

export const useGraphqlInfiniteQuery = <TData = any, TVariables = Record<string, any>>(
  operation: TypedDocumentNode<TData, TVariables>,
  variables: TVariables,
  options?: UseInfiniteQueryOptions<TData, GraphqlError>,
) => {
  const operationName = useMemo(() => getOperationName(operation), [operation]);
  const queryKey = useMemo(() => [operationName, variables ?? {}, "infinite"], [operationName, variables]);
  return useInfiniteQuery(
    queryKey,
    ({ pageParam }) => graphqlFetch(operation, { ...variables, ...pageParam }),
    // TODO: fix typing @jpagand any idea?
    options as any,
  );
};

export const useGraphqlMutation = <TData = any, TVariables = Record<string, any>>(
  operation: TypedDocumentNode<TData, TVariables>,
  options?: UseMutationOptions<TData, GraphqlError, TVariables>,
) => {
  return useMutation((variables?: TVariables) => graphqlFetch(operation, variables), options);
};
